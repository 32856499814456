import { gql } from '@apollo/client';

export default gql`
  query fetchIndividualLeaderships($id: String!) {
    getLeadershipHistory(id: $id) {
      id
      endDate
      startDate
      isDelegated
      roleId
      role
      _embedded {
        organization {
          id
          name
          type
        }
      }
    }
  }
`;
