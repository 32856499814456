import { useLazyQuery } from '@apollo/client';

import fetchIndividualLeaderships from '@queries/fetchIndividualLeadershipsQuery';

import {
  FetchIndividualLeadershipsQuery,
  FetchIndividualLeadershipsQueryVariables,
} from '@typings/operations';

export const useFetchMemberLeadershipsHistory = () =>
  useLazyQuery<
    FetchIndividualLeadershipsQuery,
    FetchIndividualLeadershipsQueryVariables
  >(fetchIndividualLeaderships);
